* {
  user-select: text; /* Allow text selection */
}
.MuiOutlinedInput-notchedOutline {
  border-color: rgba(215, 215, 215, 1) !important;
}

.MuiFilledInput-root {
  border-bottom: 1px solid rgba(215, 215, 215, 1) !important;
  border-color: rgba(215, 215, 215, 1) !important;
}

.MuiInput-root {
  border-bottom: 1px solid rgba(215, 215, 215, 1) !important;
  border-color: rgba(215, 215, 215, 1) !important;
}
.textfield {
  color: red;
}

@import url("https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Chivo", sans-serif;
}
/* Custom scrollbar styles */
::-webkit-scrollbar {
  background-color: transparent; /* Makes the scrollbar background transparent */
  width: 5px; /* Width of the vertical scrollbar */
  height: 5px; /* Height of the horizontal scrollbar */
  border-radius: 10px; /* Rounded corners for the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #61b15a; /* Color of the scrollbar thumb */
  border: none; /* Removes any border */
  border-radius: 10px; /* Rounded corners for the thumb */
}
