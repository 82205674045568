/* src/Loader.css */
.loader-container {
  width: 100%;
  height: 1000px;
  display: flex;
  justify-content: center;
  align-items: "center";
  background: transparent;
  border-radius: 10px;
}

.circle {
  border: 2px solid #8f8ef1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  opacity: 0.6;
  background: radial-gradient(#fff 45%, #6967e1);
  align-self: center;
}
.circle-1 {
  background: radial-gradient(#fff 45%, #6967e1);
  border-radius: 50%;
  width: 250px;
  height: 250px;
  opacity: 0.6;
  animation: rotateCircle 2s infinite linear;
  transform: translate(-25%, -10%) rotate(120deg);
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: "center";
  transform-origin: center;
}
.circle-2 {
  background: radial-gradient(#fff 45%, #6967e1);
  border-radius: 50%;
  width: 250px;
  height: 250px;
  opacity: 0.6;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: "center";
  /* animation: rotateCircle 2s infinite linear; */
  transform: translate(-20%, -50%) rotate(120deg);
}
.circle-3 {
  background: radial-gradient(#fff 45%, #6967e1);
  border-radius: 50%;
  width: 250px;
  height: 250px;
  opacity: 0.6;
  /* animation: scaleCircle 1.5s infinite ease-in-out; */
  align-self: center;
  /* animation: rotateCircle 2s infinite linear; */
  transform: translate(-15%, -30%) rotate(120deg);
}
.circle-4 {
  background: radial-gradient(#fff 45%, #6967e1);
  border-radius: 50%;
  width: 250px;
  height: 250px;
  opacity: 0.6;
  /* animation: scaleCircle 1.5s infinite ease-in-out; */
  align-self: center;
  /* animation: rotateCircle 2s infinite linear; */
  transform: translate(-50%, -8%) rotate(-120deg);
}

@keyframes scaleCircle {
  0%,
  100% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes scaleEllipse {
  0%,
  100% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
}
@keyframes rotateCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotateCircle-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
